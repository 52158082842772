<template>
  <div class="roleManagementPage">
    <div>
      <div>
        <h1 :style="isMobile ? 'font-size:2em;' : 'font-size: 3em;'">
          {{ $t("Role Management") }}
        </h1>
      </div>
      <div
        class="d-flex position-relative roleManagementContainer"
        style=""
        ref="detailsCard"
        v-if="gotRights"
      >
        <!-- Front Office card -->
        <div
          :style="[
            'display:flex ',
            isDetailsShow ? 'height:90%' : 'height:100%',
          ]"
        >
          <div class="front-office-card">
            <div>
              <h3 style="font-size: 18px">Front Office</h3>
              <div
                @click="showRoles(role, $event)"
                :class="{
                  highlighted: isDetailsShow && selectedRole === role,
                }"
                v-for="(role, index) in frontOfficeRoles"
                :key="index"
              >
                <div style="padding: 6px 20px; cursor: pointer" class="d-flex">
                  <div
                    style="margin-top: 1px"
                    class="d-flex justify-content-between w-100 px-1"
                  >
                    <li style="display: flex; gap: 8px; align-items: center">
                      <i class="fa fa-angle-right"></i
                      ><span>{{ $t(role.name) }} </span>
                    </li>
                  </div>
                </div>
              </div>
            </div>

            <div style="margin-top: 20px">
              <h3 style="font-size: 18px">Back Office</h3>
              <div
                @click="showRoles(role, $event)"
                :class="{
                  highlighted: isDetailsShow && selectedRole === role,
                }"
                v-for="(role, index) in backOfficeRoles"
                :key="index"
              >
                <div style="padding: 6px 20px; cursor: pointer" class="d-flex">
                  <div
                    style="margin-top: 1px"
                    class="d-flex justify-content-between w-100 px-1"
                  >
                    <li style="display: flex; gap: 8px; align-items: center">
                      <i class="fa fa-angle-right"></i
                      ><span>{{ $t(role.name) }} </span>
                    </li>
                  </div>
                </div>
              </div>

              <div class="mt-3" style="display: none">
                <CInput :label="$t('Add new role')" v-model="newRole" />
                <div style="display: flex; justify-content: end">
                  <CButton class="add-role" @click="createRole">
                    {{ $t("Create new role") }}
                    <i
                      class="fa fa-plus text-white"
                      style="font-size: 10px"
                    ></i>
                  </CButton>
                </div>
              </div>
            </div>
          </div>
          <transition name="fade" class="role-card-mobile">
            <div v-if="isDetailsShow" class="role-card">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  gap: 10px;
                  overflow: inherit;
                "
              >
                <div
                  class="align-items-center gap-2 close-rights-role"
                  @click="isDetailsShow = false"
                >
                  <i
                    class="fa fa-chevron-left"
                    style="cursor: pointer; color: #0071ce"
                  ></i>
                  <span>{{ $t("Back") }}</span>
                </div>
                <h3 class="p-0 m-0" style="font-size: 18px">
                  {{ $t("Rights for") }} {{ $t(selectedRole.name) }}
                </h3>

                <div
                  style="
                    overflow: auto;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                  "
                >
                  <!-- Grouped Accesses -->
                  <div
                    v-for="(section, key) in getGroupedAccesses(selectedRole)"
                    :key="key"
                  >
                    <h5
                      class="p-0 m-0"
                      style="font-weight: 700; font-size: 15px"
                    >
                      {{ $t(section.name) }}
                    </h5>

                    <div
                      class="d-flex gap-3"
                      v-for="(right, index) in section.permissions"
                      :key="right"
                    >
                      <div
                        class="d-flex gap-3"
                        style="align-items: center"
                        v-if="permissions[right.name]"
                      >
                        <input
                          style="margin-top: -3px"
                          :data-testid="'right-' + key + '-' + index"
                          :disabled="isDisabled(right, section)"
                          type="checkbox"
                          :id="'right-' + key + '-' + index"
                          v-model="right.status"
                          @change="rightChanged(right, section)"
                        />
                        <label
                          style="margin-top: 2.4px"
                          :for="'right-' + key + '-' + index"
                        >
                          {{ permissions[right.name] }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
        <div class="role--btns" v-if="isDetailsShow">
          <div
            style="
              display: flex;
              justify-content: end;
              background-color: white;
              gap: 20px;
            "
          >
            <div
              class="align-items-center gap-2 close-rights-role"
              @click="isDetailsShow = false"
            >
              <i
                class="fa fa-chevron-left"
                style="cursor: pointer; color: #0071ce"
              ></i>
              <span>{{ $t("Cancel") }}</span>
            </div>
            <CButton
              data-testid="saveRights"
              class="save_role"
              style="min-width: 80px"
              @click="saveRoles"
              >{{ $t("Save") }}</CButton
            >
          </div>
        </div>
      </div>
      <ContentLoader
        v-else
        :speed="2"
        primaryColor="rgb(230, 230, 230)"
        secondaryColor="rgb(240, 240, 240)"
        class="spinner_wrapper mt-4"
      ></ContentLoader>
    </div>
  </div>
</template>

<script>
import { UsersService } from "@/services/users";
import { ContentLoader } from "vue-content-loader";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import { useGlobalStore } from "../../stores/store";
import { showMessage } from "../../utils/helpers";
import permissionsText from "@/constants/permissionsText";

import { mapWritableState } from "pinia";
import { ElLoading } from "element-plus";
export default {
  name: "RoleManagement",
  components: {
    ContentLoader,
    OrganizationSelector,
  },

  data() {
    return {
      permissions: permissionsText,
      newRole: "",
      isDetailsShow: false,
      gotRights: false,
      selectedRole: null,
      selectedRights: [],
      frontOfficeRoles: [],
      backOfficeRoles: [],
    };
  },
  computed: {
    ...mapWritableState(useGlobalStore, {
      isMobile: "isMobile",
    }),
  },
  mounted() {
    this.clickOutsideListener = (event) => {
      if (!this.$refs.detailsCard.contains(event.target)) {
        this.isDetailsShow = false;
      }
    };
    document.addEventListener("click", this.clickOutsideListener);
    this.getAdvancedRoles();
  },
  beforeUnmount() {
    document.removeEventListener("click", this.clickOutsideListener);
  },
  methods: {
    getGroupedAccesses(role) {
      const groupedAccesses = [];
      const singlePermissionSections = [];

      role.accesses.forEach((access) => {
        console.log("access", access);

        // if (access.name === "Training") {
        //   return;
        // }
        if (access.permissions.length === 1) {
          singlePermissionSections.push(...access.permissions);
        } else {
          groupedAccesses.push(access);
        }
      });

      if (singlePermissionSections.length > 0) {
        groupedAccesses.push({
          name: "Other",
          permissions: singlePermissionSections,
        });
      }
      console.log("groupedAccesses", groupedAccesses);

      return groupedAccesses;
    },
    isDisabled(currentRight, rightsGroup) {
      if (
        rightsGroup.permissions.length > 1 &&
        currentRight != rightsGroup.permissions[0]
      ) {
        if (rightsGroup.permissions[0].status === false) {
          return true;
        }
      }
      if (rightsGroup.name.toLowerCase() != "actions") {
        return false;
      }

      const viewPagePermission = rightsGroup.permissions.find(
        (permission) => permission.name === "viewActionsPage"
      ).status;
      if (!viewPagePermission) {
        if (!currentRight.name.toLowerCase().includes("page")) {
          rightsGroup.permissions.forEach((permission) => {
            permission.status = false;
          });
          return true;
        }
      }
      if (currentRight.name.toLowerCase().includes("page")) {
        return false;
      }
      if (
        !currentRight.name.toLowerCase().includes("add") &&
        !currentRight.name.toLowerCase().includes("modify") &&
        !currentRight.name.toLowerCase().includes("delete") &&
        !currentRight.name.toLowerCase().includes("remove") &&
        !currentRight.name.toLowerCase().includes("view")
      ) {
        return false;
      }

      const index = rightsGroup.permissions.findIndex(
        (permission) => permission.name === currentRight.name
      );
      if (currentRight.name.toLowerCase().includes("all")) {
        if (index - 1 < rightsGroup.permissions.length) {
          return !rightsGroup.permissions[index - 1].status;
        }
      } else if (currentRight.name.toLowerCase().includes("team")) {
        if (index - 1 < rightsGroup.permissions.length) {
          return !rightsGroup.permissions[index - 1].status;
        }
      } else {
        return false;
      }
    },

    async getAdvancedRoles() {
      const loading = ElLoading.service({
        fullscreen: true,
        background: "#0a1f8f45",
      });
      const { data } = await UsersService.getAdvancedRoles();
      this.frontOfficeRoles = data.filter(
        (role) => role.scope === "organization"
      );
      this.backOfficeRoles = data.filter((role) => role.scope === "project");
      loading.close();
      this.gotRights = true;
    },
    categorizeRights(flatRights) {
      // Your categorization logic here
      const categorizedRights = {
        Organization: [],
        Actions: [],
        Agenda: [],
        Communications: [],
        Training: [],
      };

      flatRights.forEach((right) => {
        if (right.group == "Organizations") {
          categorizedRights.Organization.push(right);
        } else if (right.group == "Actions") {
          categorizedRights.Actions.push(right);
        } else if (right.group == "Agenda") {
          categorizedRights.Agenda.push(right);
        } else if (right.group == "Communication") {
          categorizedRights.Communications.push(right);
        } else if (right.group == "Training") {
          categorizedRights.Training.push(right);
        }
      });

      return categorizedRights;
    },
    rightChanged(selectedRight, rightsGroup) {
      if (
        selectedRight == rightsGroup.permissions[0] &&
        selectedRight.status == false
      ) {
        rightsGroup.permissions.forEach((permission) => {
          permission.status = false;
        });
        return;
      }
      if (rightsGroup.name.toLowerCase() != "actions") {
        return;
      }
      if (selectedRight.name.toLowerCase().includes("page")) {
        return;
      }
      if (
        !selectedRight.name.toLowerCase().includes("add") &&
        !selectedRight.name.toLowerCase().includes("modify") &&
        !selectedRight.name.toLowerCase().includes("delete") &&
        !selectedRight.name.toLowerCase().includes("remove") &&
        !selectedRight.name.toLowerCase().includes("view")
      ) {
        return;
      }

      const index = rightsGroup.permissions.findIndex(
        (permission) => permission.name === selectedRight.name
      );
      if (selectedRight.name.toLowerCase().includes("all")) {
      } else if (selectedRight.name.toLowerCase().includes("team")) {
        if (!selectedRight.status) {
          rightsGroup.permissions[index + 1].status = false;
        }
      } else {
        if (!selectedRight.status) {
          rightsGroup.permissions[index + 1].status = false;
          rightsGroup.permissions[index + 2].status = false;
        }
      }
    },
    async saveRoles() {
      const payload = {
        data: [...this.frontOfficeRoles, ...this.backOfficeRoles],
      };

      const data = await UsersService.saveAdvancedRoles(payload);
      if (data.success) {
        showMessage("success", "Saved roles successfully!");
      } else {
        showMessage("error", "Failed to save roles!");
      }
      if (this.isMobile) {
        this.isDetailsShow = false;
      }
    },
    showRoles(role, event) {
      event.stopPropagation();
      this.selectedRole = role;
      let roleRights =
        this.frontOfficeRoles.find((r) => r.name === role.name) ||
        this.backOfficeRoles.find((r) => r.name === role.name);
      if (roleRights) {
        this.selectedRights = roleRights.rights;
      }
      this.isDetailsShow = true;
    },
    createRole() {
      if (this.newRole.trim() !== "") {
        const newRoleObj = { name: this.newRole };
        this.backOfficeRoles.push(newRoleObj);
        this.newRole = "";
      }
    },
    cardHeight() {
      const frontOfficeRolesHeight = this.frontOfficeRoles.length * 3.1;
      const backOfficeRolesHeight = this.backOfficeRoles.length * 3.1;
      const totalHeight = frontOfficeRolesHeight + backOfficeRolesHeight;
      const minHeight = 20;
      return Math.max(totalHeight, minHeight);
    },
  },
};
</script>

<style scoped>
.img {
  margin-top: 5px;
  margin-right: 4px;
  width: 12px;
  height: 12px;
  cursor: pointer;
}

li::marker {
  content: "";
}
.roleManagementContainer {
  margin: 0;
  height: calc(100vh - 11rem);
  width: 50vw;
  display: flex;
  flex-direction: column;
}

.role--btns {
  background-color: white;
  z-index: 11;
  height: 10%;
  padding: 26px;
  border: 1px solid #c5c5c5;
  border-top: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.add-role {
  background-color: #0071ce;
  border-radius: 0px 0px 0px 25px;
  height: 40px;
  color: white;
  margin-top: 10px;
}
.save_role {
  background-color: #0071ce;
  border-radius: 0px 0px 0px 25px;
  color: white;
  height: 35px;
}
.highlighted {
  position: relative;
  color: #0071ce;
  /* z-index: 1; */
}
.highlighted::after {
  background-color: #e7f0fa;
  border-radius: 0;
  position: absolute;
  top: 0;
  left: -200px;
  width: 100vw;
  height: 100%;
  content: "";
  z-index: 0;
}
.highlighted i {
  color: #0071ce;
  z-index: 13;
}
.highlighted span {
  color: #0071ce;
  z-index: 13;
}
.orgProjectDropdown {
  width: max-content;
  position: relative;
  bottom: 20px;
  left: 20px;
}

.front-office-card {
  padding: 20px;
  width: 50%;
  background-color: white;
  min-width: auto;
  border: 1px solid #c5c5c5;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
.role-card {
  height: 100%;
  width: 50%;
  background-color: white;
  /* box-shadow: 23px 4px 30px hsla(0, 0%, 50.6%, 0.534); */
  padding: 20px 0 0 25px;
  overflow: auto;
  position: relative;
  min-width: auto;
  display: flex;
  flex-direction: column;
  /* padding: 0 9px; */
  border: 1px solid #c5c5c5;
  border-left: 0;
  /* z-index: 15; */
}
@media (max-width: 1008px) {
  .roleManagementContainer {
    width: 70%;
  }
}
@media (max-width: 1133px) {
  .orgProjectDropdown {
    width: max-content;
    position: relative;
    top: 0;
    left: 20px;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s, transform 0.3s ease-in;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}
.close-rights-role {
  display: none;
}
@media (max-width: 853px) {
  .roleManagementContainer {
    width: 75%;
  }
}
@media (max-width: 970px) {
  .roleManagementPage {
    margin-top: 50px;
  }
}
@media (max-width: 650px) {
  .roleManagementPage {
    margin-top: 0;
  }
}
@media (max-width: 853px) {
  .roleManagementContainer {
    width: 95%;
    /* height: 600px; */
  }
}
@media (max-width: 650px) {
  h1 {
    padding: 0 !important;
  }
  .highlighted span,
  .highlighted i {
    z-index: 0;
  }
  .roleManagementContainer {
    margin: 0;
    height: calc(100vh - 12rem);
  }
  .role-card-mobile {
    position: absolute !important;
    left: 0;
    width: 100%;
    margin: 0 1rem !important;
    min-width: auto !important;
  }
  .close-rights-role {
    display: flex;
    color: #0071ce;
  }
  .front-office-card {
    margin: 0 1rem;
    width: 100%;
  }
  .roleManagementContainer {
    width: 100%;
  }
  .role-card-mobile {
    width: inherit;
    height: 90%;
    border-left: unset;
    border: 1px solid #c5c5c5;
    z-index: 0;
  }
  .role--btns {
    margin: 0 1rem;
    z-index: 16;
  }
  h1 {
    font-size: 19px;
    margin: 0;
    height: unset;
  }
}
@media (max-width: 425px) {
  .roleManagementContainer {
    margin: unset;
    /* height: calc(100vh - 200px); */
  }
}
</style>
